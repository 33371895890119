exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carnes-js": () => import("./../../../src/pages/carnes.js" /* webpackChunkName: "component---src-pages-carnes-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-peixe-js": () => import("./../../../src/pages/peixe.js" /* webpackChunkName: "component---src-pages-peixe-js" */),
  "component---src-pages-recomchefe-js": () => import("./../../../src/pages/recomchefe.js" /* webpackChunkName: "component---src-pages-recomchefe-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobremesas-js": () => import("./../../../src/pages/sobremesas.js" /* webpackChunkName: "component---src-pages-sobremesas-js" */)
}

